const ENV_FILE_PATH = {
    localhost : 'development',
    dev : 'development',
    qa: 'qa',
    stg: 'stage',
    stgdr: 'stagedr',  
    prd: 'production',
    prddr: 'productiondr'   
}
const isLocal = window.location.hostname.match(/localhost/);
const env = window.location.hostname.match(/dev|qa|stg|prd/);
const hostname = window.location.hostname;
const externalPrdUrl = 'udocsweb.ual.com';
const externalPrdUrlNew = 'udocs.ual.com';

export const getEnvironment = async () => {
    let envfilepath = ENV_FILE_PATH[env];

    if (isLocal) {
        envfilepath = ENV_FILE_PATH['dev'];
    }
    else if(env == 'qa'){
        envfilepath = ENV_FILE_PATH['qa'];
    }
    else if(env == 'stg'){
        envfilepath = ENV_FILE_PATH['stg'];
    }
    else if(env == 'prd' || hostname === externalPrdUrl || hostname === externalPrdUrlNew){
        envfilepath = ENV_FILE_PATH['prd'];
    }
    else if(env == null || env == undefined){
        envfilepath = ENV_FILE_PATH['dev'];        
    }

    return envfilepath;
};