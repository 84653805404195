import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery, useMutation,useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { HomeContext } from '../../containers/Home/HomeContext';
import { ViewerContext } from '../../containers/Viewer/ViewerContext';

import styles from './doctile.module.scss';
import Icon from '../Icon/Icon';
import PinItems from './PinItems/PinItems';
import ManualAlert from './ManualAlert/ManualAlert';
import { GET_RECENT_MANUALS, GET_FAVORITE_MANUALS,GET_MANUAL_VERSION_ACTIVITY } from '../../operations/queries/manuals';
// import { GET_EMP_PROFILE } from '../../operations/queries/preferences';
import { mutations, UPDATE_EMP_FAV } from '../../operations/mutations';

const DocTile = (props) => {
    const { title, info, category, isFav, empId, isHtmlManual, isSearchEnable ,isDocumentCollection,manualCode} = props;
    const recentManuals = useQuery(GET_RECENT_MANUALS);
    const favoriteManuals = useQuery(GET_FAVORITE_MANUALS);
    /* const profileinfo = useQuery(GET_EMP_PROFILE, {
		variables: {
            userId: userId
          }
        });*/

    const { selectedPinManualCode, setSelectedPinManualCode, selectedAlertManualCode, setSelectedAlertManualCode } = useContext(HomeContext);
    const { setSelectedDocument, setHtml, setSearchEnabled } = useContext(ViewerContext);

    const [updateFavorite, { data }] = useMutation(UPDATE_EMP_FAV);
    const [getVersionActivity,{loading , error, data:activityData}]=useLazyQuery(GET_MANUAL_VERSION_ACTIVITY)
    const { selectManual, setRecent, setFavorites } = mutations;
    
    const history = useHistory();
    const location = useLocation();

    const [isFavorite, setIsFavorite] = useState(false);
    const [refreshDate,setRefreshDate]=useState(null)

    useEffect(() => {
        setIsFavorite(isFav);
    }, [isFav])
    useEffect(()=>{
        if(isDocumentCollection){
            getVersionActivity({variables:{manualCode:manualCode,isPreview:false  },fetchPolicy: 'network-only'})   
        }
    },[isDocumentCollection])
    useEffect(()=>{
        if(activityData&&activityData.manualVersionActivity.latestRefreshDate){
            setRefreshDate(activityData.manualVersionActivity.latestRefreshDate.map(m=>m.modifiedDate))
        }

    },[activityData])

    const handleViewManualClick = () => {
        const newrecent = { categoryName: category, manualName: title, manualCode: info.manualCode, hasAlerts: info.hasAlerts, revDate: info.revDate, revNumber: info.revision ? info.revision : '',
                            uRL: info.uRL, typeInfo: info.fleetType || info.vendorName || info.engineType || null,
                            fleetType: info.fleetType || null, vendorName: info.vendorName || null, engineType: info.engineType || null };
        const currentrecent = [...recentManuals.data.recent];
        if (recentManuals.data.recent.findIndex(r => r.manualCode === info.manualCode) === -1) {
            if (recentManuals.data.recent.length > 9) currentrecent.pop();
            currentrecent.unshift(newrecent);
        }
        setSelectedDocument(null);
        setRecent(currentrecent);
        selectManual(newrecent);
        setHtml(isHtmlManual);
        setSearchEnabled(isSearchEnable);
        if(info.uRL)
            window.open(info.uRL, '_blank')
        else
        history.push(`/viewer/${newrecent.manualCode}`);

    }
    const handleFavoriteClick = (isfav) => {
        const currentfavs = [...favoriteManuals.data.favorites];
        const userId = empId; //userId || 'V726082';
        if (userId) {
            if (isfav) currentfavs.unshift({ manualCode: info.manualCode });
            else {
                const index = currentfavs.findIndex(f => f.manualCode === info.manualCode);
                if (index !== -1) currentfavs.splice(index, 1); 
            }
            updateFavorite({ variables: {
                    "employeeId": userId,
                    "manualCode": info.manualCode, 
                    "isActive": isfav
                } 
            });

            setFavorites(currentfavs);
            if (location.pathname !== '/favorites') setIsFavorite(isfav);
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                <div className={styles.lockIcon}>
                    <span>{title}</span>
                    {info?.isF117Manual &&<Icon variant='lock' width='30px' height='34px' clickable={false}/>}
                </div>
            </div>
            <div className={styles.info}>
                <div>{category === 'Fleet' ? info.fleetType : category === 'Vendor' ? info.vendorName : info.engineType}</div>
                {/* <div>{doc.info.type}</div> */}
                <div>
                    <div className={styles.divFormat}>{ isHtmlManual? 'HTML' : 'PDF'} </div> 
                    {(!isDocumentCollection)?
                    <>
                    {(info.revision !=null)
                    ? <div>REV # {info.revision ? info.revision + ' - ' : ''}{moment(info.revDate).format('MM/DD/YYYY')}</div>
                    : <div></div> }
                    </>
                    : (refreshDate!==null)?<div>Last Updated On: {new Date(refreshDate).toLocaleDateString()}</div>: <div></div> 
                }
                </div>
            </div>
            <div className={styles.footer}>
                {
                 info.hasPins ? 
                <div className={styles.bookmark}>
                    <div onClick={() => {setSelectedPinManualCode(info.manualCode); setSelectedAlertManualCode(null)}}><Icon variant='pinned' className={styles['pinned-item']} clickable='true' /></div>
                    <div onClick={() => {setSelectedPinManualCode(info.manualCode); setSelectedAlertManualCode(null)}} className={styles.label}>View Pins</div>
                    {info.hasAlerts ? 
                    <div onClick={() => {setSelectedAlertManualCode(info.manualCode); setSelectedPinManualCode(null)}} className={styles.alert}><div className={styles.itemEX}>!</div></div>
                    : null
                    }
                </div>
                :
                <div className={styles.bookmark}>
                    <div><Icon variant='unpinned' className={styles['pinned-item']} clickable='false' /></div>
                    {info.hasAlerts ? 
                    <div onClick={() => {setSelectedAlertManualCode(info.manualCode); setSelectedPinManualCode(null)}} className={styles.alert}><div className={styles.itemEX}>!</div></div>
                    : null
                    }
                </div>
                }
                {
                    selectedAlertManualCode === info.manualCode ?
                        <ManualAlert setShow={setSelectedAlertManualCode} manualCode={info.manualCode} />
                    : null
                }
                {
                    selectedPinManualCode === info.manualCode ?
                        <PinItems title={title} info={info} category={category} setShow={setSelectedPinManualCode} manualCode={info.manualCode} empId={empId} isHtmlManual={isHtmlManual} isSearchEnable={isSearchEnable}/>
                    : null
                }
                <div className={styles.actions}>
                    <div><Icon variant={isFavorite ? 'favorite' : 'unfavorite'} width="26px" height="25px" onClick={() => handleFavoriteClick(!isFavorite)} clickable='true'/></div>
                    <div className={styles.manual} onClick={() => handleViewManualClick()}><div>View Manual</div><div className={styles.arrow}></div></div>
                </div>
            </div>
        </div>
    )
}

export default DocTile;