import React, { useState, useEffect, useContext, useRef } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import cx from 'classnames';

import Icon from '../../Icon/Icon';
import styles from './tablecontents.module.scss';
import { ViewerContext } from '../../../containers/Viewer/ViewerContext';
import { GET_EMP_PROFILE } from '../../../operations/queries/preferences';
import { UPDATE_EMP_MANUAL_PIN, UPDATE_SEL_MANUAL, mutations } from '../../../operations/mutations';
import { UserContext } from '../../../containers/App/UserContext';
import { GET_MANUAL_PINS, GET_EMP_PINS } from '../../../operations/queries/manuals';
import { Virtuoso } from "react-virtuoso";


const TableContents = (props) => {
    const { userId } = useContext(UserContext);
    const { manualCode, isPreview, setSelectedDocument, setViewerLoading, isHtmlManual, innerHeight ,manualName, revNumber, revDate, fleet} = props;
    const { setPins } = mutations;

    // const tablecontents = useQuery(GET_TABLE_CONTENTS);
    const profileinfo = useQuery(GET_EMP_PROFILE, {
		variables: {
		  userId: userId
		}
	  });

    const pinsinfo = useQuery(GET_MANUAL_PINS);

    const [updateSelectedManual, { data }] = useMutation(UPDATE_SEL_MANUAL);
    const [updateManualPin] = useMutation(UPDATE_EMP_MANUAL_PIN);
    const [getPins, { loading: pinsloading, data: pinsdata }] = useLazyQuery(GET_EMP_PINS, { fetchPolicy: 'network-only' });
    const {currentSelectedSections, setCurrentSelectedSections,currentSelectedSubSections, setCurrentSelectedSubSections, scrollPosition, setScrollPosition, currentManualCode, setCurrentManualCode, currentPreviewStatus, setCurrentPreviewStatus, tableContent, setTableContent, setSelectedTrail, setPdfWorkerLoading} = useContext(ViewerContext);
    const [selectedSections, setSelectedSections] = useState([]);
    const [selectedAdminManualIds, setSelectedAdminManualIds] = useState([]);

    const [selectedSubSections, setSelectedSubSections] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubSecLoading, setIsSubSecLoading] = useState(false);

    useEffect(() => {   
        if (profileinfo && profileinfo.data && profileinfo.data.profileQuery && profileinfo.data.profileQuery.profile) {
                getPins({variables: { employeeId: userId || 'V726082', manualCode: manualCode }})}
            //getPins({variables: { employeeId: 'v753687', manualCode: manualCode }});
            //if switch to another manual, call endpoint to get table of content, otherwise use context to display the table of content.
            if(manualCode!=currentManualCode || isPreview!=currentPreviewStatus)
            {
                setCurrentManualCode(manualCode);
                setCurrentPreviewStatus(isPreview);
                setTableContent();
                updateSelectedManual({ variables: {
                        //"employeeId": 'v753687', 
                        "employeeId": userId || 'V726082',
                        "manualCode": manualCode,
                        "isPreview" : isPreview,
                        "adminManualId":0,
                        "isFromUDocs":true
                    } 
                });

            }
            //if same manual, but table of content is empty, call endpoint to refresh the content.
            else if(!tableContent || !tableContent.manuals)
            {
                updateSelectedManual({ variables: {
                    //"employeeId": 'v753687', 
                    "employeeId": userId || 'V726082',
                    "manualCode": manualCode,
                    "isPreview" : isPreview,
                    "adminManualId":0,
                    "isFromUDocs":true
                } 
                });
            }
        
    }, [manualCode])

    useEffect(()=> {     
        if ( data && data.manuals )
        {
            //update table of content context
            if(!tableContent) {
                setTableContent(data);    
            }
            else {
                if(tableContent?.manuals?.getManual?.isDocumentCollection) {
                const tableContentCopy = {...tableContent};
                //const modifiedContentCopy = tableContentCopy.manuals.getManual.subTree.map((val, key)=>((val.title === selectedSections[selectedSections.length-1]) ? ({...val, subTree:data.manuals.getManual.subTree}):val));
                const modifiedContentCopy = docCollectionStateUpdate(tableContentCopy.manuals.getManual, selectedAdminManualIds, data.manuals.getManual);

                setTableContent({...tableContentCopy, manuals: {
                    ...tableContentCopy.manuals,
                    getManual:{
                        ...tableContentCopy.manuals.getManual,
                        subTree:modifiedContentCopy?.subTree
                    }
                }
            })
            setIsLoading(false);
            setIsSubSecLoading(false);
            }
        }

        }
    }, [data]);

    const docCollectionStateUpdate = (obj, id, update) => {
        if (obj.adminManualId === id) {
          return { ...obj, ...update };
        }
        if (obj.subTree) {
          return {
            ...obj,
            subTree: obj.subTree.map((child) => docCollectionStateUpdate(child, id, update)),
          };
        }
        return obj;
      };
      
    useEffect(() => {
        if (pinsdata && pinsdata.manuals.getPinnedDocuments) {
           // debugger
            const pins = pinsdata.manuals.getPinnedDocuments.map(p => {
                return {
                    manualCode: manualCode, 
                    fileName: p.fileName,
                    title: p.title, 
                    subTitle: p.subTitle
                }
            });
            setPins(pins);
        }
    }, [pinsdata])

    const handleSectionClick = (section, adminManualId) => {
        const sections = [...selectedSections];
        if (sections.findIndex(s => s === section) === -1) {
            sections.push(section);
            if(tableContent?.manuals?.getManual?.isDocumentCollection) {
                setIsLoading(true);
                updateSelectedManual({ variables: {
                    //"employeeId": 'v753687', 
                    "employeeId": userId || 'V726082', 
                    "manualCode": manualCode,
                    "isPreview" : isPreview,
                    "adminManualId":adminManualId,
                    "isFromUDocs":true
                    } 
                });
                setSelectedAdminManualIds(adminManualId);
            }
        }
        else sections.pop(section);
        setSelectedSections([...sections]);
        setCurrentSelectedSections([...sections]);
    }
    const handleSubSectionClick = (section, subsection, adminManualId) => {
        
        const entry = section+subsection;
        const subsections = [...selectedSubSections];
        if (subsections.findIndex(ss => ss === entry) === -1) { 
            subsections.push(entry);

            if(tableContent?.manuals?.getManual?.isDocumentCollection) {
                setIsSubSecLoading(true);
                updateSelectedManual({ variables: {
                    //"employeeId": 'v753687', 
                    "employeeId": userId || 'V726082', 
                    "manualCode": manualCode,
                    "isPreview" : isPreview,
                    "adminManualId":adminManualId,
                    "isFromUDocs":true
                    } 
                });
                setSelectedAdminManualIds(adminManualId);
            }
        
        }
        else {
            if(tableContent?.manuals?.getManual?.isDocumentCollection) {
            const tableContentCopy = {...tableContent};

            const modifiedContentCopy = docCollectionStateUpdate(tableContentCopy.manuals.getManual, adminManualId, {subTree:null});

            setTableContent({...tableContentCopy, manuals: {
                ...tableContentCopy.manuals,
                getManual:{
                    ...tableContentCopy.manuals.getManual,
                    subTree:modifiedContentCopy?.subTree
                }
            }
        })
    }
            subsections.pop(entry);
        
        };
        setSelectedSubSections([...subsections]);
        setCurrentSelectedSubSections([...subsections]);
    }
    const handlePinClick = (isPinning, fileName, title, subTitle) => {
        const currentpins = [...pinsinfo.data.pins];
        if (isPinning) currentpins.unshift({ manualCode: manualCode, fileName: fileName, title: title, subTitle: subTitle});
        else {
            const index = currentpins.findIndex(p => p.fileName === fileName);
            if (index !== -1) currentpins.splice(index, 1); 
        }
        setPins(currentpins);
        updateManualPin({ variables: {
                //employeeId:"v753687",
                employeeId: userId || 'V726082', 
                manualCode: manualCode, 
                fileName: fileName, 
                isActive: isPinning
            } 
        });
    }
    //save y positon when tale of content div scroll
    const tableofcontent = React.useRef(null);
    const handleWindowScroll = () => { 
        setScrollPosition(tableofcontent.current.scrollTop);
        //console.log("event_position", scrollPosition);
    }
    //set y scroll positon when load table of content if same manual
    useEffect(()=> { 
        
         if ( tableContent && tableContent.manuals && scrollPosition) {
        //     //console.log("scroll_position",scrollPosition);
             tableofcontent.current.scrollTop=scrollPosition;
           }   
    }, [tableContent]);

    const handleSelectedTrail = (trailSection, trailSubsection, trailDetail)=>{
        /*const newTrail = [];
        if(trailDetail) newTrail.push(trailDetail.title);
        if(trailSubsection) newTrail.push(trailSubsection.title);
        if(trailSection) newTrail.push(trailSection.title);

        setSelectedTrail([...newTrail]);*/
    }

    const rendererInsideSSS = (index, grandChildItem, childItem, parentItem) => { 
        return <>
                    {
                             grandChildItem.fileName ? <div style={{paddingTop:'5px'}}>
                                <ul>
                                <li key={`${grandChildItem.title}-${index}`} className={cx(styles['doc-info'], {[styles['hide-section']]: selectedSubSections.findIndex(sel => sel === parentItem.title+childItem.title) === -1 && currentSelectedSubSections.findIndex(sel=> sel===parentItem.title+childItem.title) === -1})}>
                                    <div className={styles['doc-title']} onClick={() => {setViewerLoading(true); setSelectedDocument(grandChildItem); handleSelectedTrail(parentItem, childItem, grandChildItem); setPdfWorkerLoading(true);  props.close(); }}>
                                        <div>{grandChildItem.title}</div>
                                        <div>{grandChildItem.subTitle}</div>
                                    </div>
                                    <div className={styles['doc-actions']}>
                                        <div>
                                            { !isPreview ? 
                                                pinsinfo && pinsinfo.data && pinsinfo.data.pins.findIndex(p => p.fileName === grandChildItem.fileName) !== -1 ? 
                                                <Icon onClick={() => handlePinClick(false, grandChildItem.fileName, grandChildItem.title, grandChildItem.subTitle)} className={styles['pinned-item']} variant='pinned' width='24px' height='23px' /> : 
                                                <Icon onClick={() => handlePinClick(true, grandChildItem.fileName, grandChildItem.title, grandChildItem.subTitle)} variant='unpinned' width='24px' height='23px' />
                                                : null
                                            }
                                        </div>
                                        {
                                            !isHtmlManual ? 
                                        <div onClick={() => window.open(`/udocs/pdfviewer?manualCode=${manualCode}&fileName=${grandChildItem.fileName}&isPreview=${isPreview}&title=${grandChildItem.title}`, '_blank', 'noopener noreferrer')}><Icon variant='popout' /></div>
                                        :
                                        <div onClick={() => window.open(`/udocs/htmlviewer?manualCode=${manualCode}&fileName=${grandChildItem.fileName}&isPreview=${isPreview}&title=${grandChildItem.title}&manualName=${manualName}&revNumber=${revNumber}&revDate=${revDate}&fleet=${fleet}`, '_blank', 'noopener noreferrer')}><Icon variant='popout' /></div>
                                        }
                                    </div>
                                </li>
                                </ul>
                                </div>
                            :
                                null
                    }
        </> 
    
    }

    const rendererInsideSS = (index, childItem, parentItem) => { 
        return (
            childItem.fileName ? 
            <div style={{paddingTop:'5px', paddingBottom:'5px'}}>
                <ul key={`${childItem.title}-${index}`}>

                    <li className={`${styles['doc-info']} ${styles['doc-first-item']}`}>

                        <div className={styles['doc-title']} onClick={() => {setViewerLoading(true); setSelectedDocument(childItem); handleSelectedTrail(parentItem, childItem, null); setPdfWorkerLoading(true);  props.close();}}>
                            <div>{childItem.title}</div>
                            <div>{childItem.subTitle}</div>
                        </div>
                        <div className={styles['doc-actions']}>
                            <div>
                                {
                                    !isPreview ? 
                                    pinsinfo && pinsinfo.data && pinsinfo.data.pins.findIndex(p => p.fileName === childItem.fileName) !== -1 ? 
                                    <Icon onClick={() => handlePinClick(false, childItem.fileName, childItem.title, childItem.subTitle)} className={styles['pinned-item']} variant='pinned' width='24px' height='23px' /> : 
                                    <Icon onClick={() => handlePinClick(true, childItem.fileName, childItem.title, childItem.subTitle)} variant='unpinned' width='24px' height='23px' />
                                    : null
                                }
                            </div>
                            {
                                !isHtmlManual ? 
                            <div onClick={() => window.open(`/udocs/pdfviewer?manualCode=${manualCode}&fileName=${childItem.fileName}&isPreview=${isPreview}&title=${childItem.title}`, '_blank', 'noopener noreferrer')}><Icon variant='popout' /></div>
                            :
                            <div onClick={() => window.open(`/udocs/htmlviewer?manualCode=${manualCode}&fileName=${childItem.fileName}&isPreview=${isPreview}&title=${childItem.title}&manualName=${manualName}&revNumber=${revNumber}&revDate=${revDate}&fleet=${fleet}`, '_blank', 'noopener noreferrer')}><Icon variant='popout' /></div>
                            }
                        </div>
                    </li>
                </ul>
                </div>
            :
            <div style={{paddingTop:'5px'}}>
            <ul key={`${childItem.title}-${index}`}>
                <li className={styles['manual-sub-sec-title']} onClick={() => handleSubSectionClick(parentItem.title, childItem.title, childItem.adminManualId)}>
                    <div className={styles.title}>{childItem.title}</div><div className={selectedSubSections.findIndex(sel => sel === parentItem.title+childItem.title) === -1 && currentSelectedSubSections.findIndex(sel=> sel===parentItem.title+childItem.title) === -1 ? styles['right-arrow'] : styles.arrow}></div>
                </li>
                {
       ( tableContent?.manuals?.getManual?.isDocumentCollection && !childItem?.subTree && isSubSecLoading && (selectedSections[selectedSections?.length-1]===parentItem.title) && currentSelectedSubSections[currentSelectedSubSections?.length-1] === parentItem.title+childItem.title) ? 

        <div className={`${styles['contents-spinner-container']} ${styles['spinner-section']}`}>
            <div className={styles.spinner}></div>
        </div> :
                    childItem?.subTree?.length>0 && 
                    <div>
                    <Virtuoso
                        style={{ paddingTop: '10px' }} 
                        className={'virtuoso-scroll'}
                        data={childItem?.subTree}
                        itemContent = {(index, item)=>(
                                rendererInsideSSS(index, item, childItem, parentItem)
                            )
                        }
                        useWindowScroll={true}
                    />
                    </div>
                }
                           
            </ul>
            </div>
        )


    }

    const rowRenderer = (index, parentItem) => {
        return <>
            { tableContent && tableContent.manuals ? 
                       
                       parentItem.fileName ? <div>
                                        <ul key={`${parentItem.title}-${index}`}>
                                            <li className={`${styles['doc-info']} ${styles['doc-first-item']}`}>
                                                <div className={styles['doc-title']} onClick={() => {setViewerLoading(true); setSelectedDocument(parentItem); handleSelectedTrail(parentItem, null, null); setPdfWorkerLoading(true); props.close();}}>
                                                    <div>{parentItem.title}</div>
                                                    <div>{parentItem.subTitle}</div>
                                                </div>
                                                <div className={styles['doc-actions']}>
                                                    <div>
                                                        {
                                                            !isPreview ? 
                                                            pinsinfo && pinsinfo.data && pinsinfo.data.pins.findIndex(p => p.fileName === parentItem.fileName) !== -1 ? 
                                                            <Icon onClick={() => handlePinClick(false, parentItem.fileName, parentItem.title, parentItem.subTitle)} className={styles['pinned-item']} variant='pinned' width='24px' height='23px' /> : 
                                                            <Icon onClick={() => handlePinClick(true, parentItem.fileName, parentItem.title, parentItem.subTitle)} variant='unpinned' width='24px' height='23px' />
                                                            : null
                                                        }
                                                    </div>
                                                    {
                                                        !isHtmlManual ? 
                                                    <div onClick={() => window.open(`/udocs/pdfviewer?manualCode=${manualCode}&fileName=${parentItem.fileName}&isPreview=${isPreview}&title=${parentItem.title}`, '_blank', 'noopener noreferrer')}><Icon variant='popout' /></div>
                                                    :
                                                    <div onClick={() => window.open(`/udocs/htmlviewer?manualCode=${manualCode}&fileName=${parentItem.fileName}&isPreview=${isPreview}&title=${parentItem.title}&manualName=${manualName}&revNumber=${revNumber}&revDate=${revDate}&fleet=${fleet}`, '_blank', 'noopener noreferrer')}><Icon variant='popout' /></div>
                                                    }
                                                </div>
                                            </li>
                                        </ul>
                                        </div>
                                    : <div>
                                    <ul key={`${parentItem.title}-${index}`}>
                                        <li className={styles['manual-sec-title']} onClick={() => handleSectionClick(parentItem.title,parentItem?.adminManualId)}>
                                            <div>{parentItem.title}</div><div className={selectedSections.findIndex(sel => sel === parentItem.title) === -1 && currentSelectedSections.findIndex(sel=>sel ===parentItem.title) === -1 ? styles['right-arrow'] : styles.arrow}></div>
                                        </li>
                                        <li className={cx(styles['manual-sec-body'], {[styles['hide-section']]: selectedSections.findIndex(sel => sel === parentItem.title) === -1 && currentSelectedSections.findIndex(sel=>sel ===parentItem.title) === -1})}>
                                        {
                                            ( tableContent?.manuals?.getManual?.isDocumentCollection && !parentItem.subTree && isLoading && (selectedSections[selectedSections?.length-1]===parentItem.title)) ? 
                                            <div className={`${styles['contents-spinner-container']} ${styles['spinner-section']}`}>
                                                <div className={styles.spinner}></div>
                                            </div> 
                                            :
                                            parentItem?.subTree?.length>0 &&
                                            <div>
                                            <Virtuoso
                                                className={'virtuoso-scroll'}
                                                style={{ paddingBottom: '10px'}}
                                                data={parentItem?.subTree}
                                                itemContent = {(index, item)=>{
                                                    return rendererInsideSS(index, item, parentItem)
                                                }}
                                                useWindowScroll={true}
                                            />
                                            </div>
                                        }
                                        </li>
                                    </ul>
                                    </div>
            : 
                <div className={`${styles['contents-spinner-container']} ${styles['spinner-section']}`}>
                    <div className={styles.spinner}></div>
                </div>
            }
        </>
    }

    const defaultRenderer = ()=>
        <>
            { tableContent && tableContent?.manuals ? 
                <div className={styles.tablecontents} style={{maxHeight: innerHeight}} ref={tableofcontent} onScroll={()=>{handleWindowScroll();}}>
                    <ul>
                        {/* <input id='manual-toggle' className={styles['manual-toggle']} type='checkbox' />  */}
                        <li className={styles['manual-title']}>{tableContent.manuals.getManual.title}</li>
                        <li className={styles['manual-body']}> 
                        {
                            tableContent?.manuals?.getManual?.subTree?.map(s => {
                                return (
                                    s.fileName ? 
                                        <ul key={s.title}>
                                            <li className={`${styles['doc-info']} ${styles['doc-first-item']}`}>
                                                <div className={styles['doc-title']} onClick={() => {setViewerLoading(true); setSelectedDocument(s); handleSelectedTrail(s, null, null); setPdfWorkerLoading(true); props.close();}}>
                                                    <div>{s.title}</div>
                                                    <div>{s.subTitle}</div>
                                                </div>
                                                <div className={styles['doc-actions']}>
                                                    <div>
                                                        {
                                                            !isPreview ? 
                                                            pinsinfo && pinsinfo.data && pinsinfo.data.pins.findIndex(p => p.fileName === s.fileName) !== -1 ? 
                                                            <Icon onClick={() => handlePinClick(false, s.fileName, s.title, s.subTitle)} className={styles['pinned-item']} variant='pinned' width='24px' height='23px' /> : 
                                                            <Icon onClick={() => handlePinClick(true, s.fileName, s.title, s.subTitle)} variant='unpinned' width='24px' height='23px' />
                                                            : null
                                                        }
                                                    </div>
                                                    {
                                                        !isHtmlManual ? 
                                                    <div onClick={() => window.open(`/udocs/pdfviewer?manualCode=${manualCode}&fileName=${s.fileName}&isPreview=${isPreview}&title=${s.title}`, '_blank', 'noopener noreferrer')}><Icon variant='popout' /></div>
                                                    :
                                                    <div onClick={() => window.open(`/udocs/htmlviewer?manualCode=${manualCode}&fileName=${s.fileName}&isPreview=${isPreview}&title=${s.title}&manualName=${manualName}&revNumber=${revNumber}&revDate=${revDate}&fleet=${fleet}&isSsi=${s.isSsi}`, '_blank', 'noopener noreferrer')}><Icon variant='popout' /></div>
                                                    }
                                                </div>
                                            </li>
                                        </ul>
                                    :
                                    <ul key={s.title}>
                                        <li className={styles['manual-sec-title']} onClick={() => handleSectionClick(s.title)}>
                                            <div>{s.title}</div><div className={selectedSections.findIndex(sel => sel === s.title) === -1 && currentSelectedSections.findIndex(sel=>sel ===s.title) === -1 ? styles['right-arrow'] : styles.arrow}></div>
                                        </li>
                                        <li className={cx(styles['manual-sec-body'], {[styles['hide-section']]: selectedSections.findIndex(sel => sel === s.title) === -1 && currentSelectedSections.findIndex(sel=>sel ===s.title) === -1})}>
                                        {
                                            s.subTree && s.subTree.map((ss, ssi) => {
                                                return (
                                                    ss.fileName ?
                                                        <ul key={ss.title}>
                                                            <li className={`${styles['doc-info']} ${styles['doc-first-item']}`}>
                                                                <div className={styles['doc-title']} onClick={() => {setViewerLoading(true); setSelectedDocument(ss); handleSelectedTrail(s, ss, null); setPdfWorkerLoading(true); props.close();}}>
                                                                    <div>{ss.title}</div>
                                                                    <div>{ss.subTitle}</div>
                                                                </div>
                                                                <div className={styles['doc-actions']}>
                                                                    <div>
                                                                        {
                                                                            !isPreview ? 
                                                                            pinsinfo && pinsinfo.data && pinsinfo.data.pins.findIndex(p => p.fileName === ss.fileName) !== -1 ? 
                                                                            <Icon onClick={() => handlePinClick(false, ss.fileName, ss.title, ss.subTitle)} className={styles['pinned-item']} variant='pinned' width='24px' height='23px' /> : 
                                                                            <Icon onClick={() => handlePinClick(true, ss.fileName, ss.title, ss.subTitle)} variant='unpinned' width='24px' height='23px' />
                                                                            : null
                                                                        }
                                                                    </div>
                                                                    {
                                                                        !isHtmlManual ? 
                                                                    <div onClick={() => window.open(`/udocs/pdfviewer?manualCode=${manualCode}&fileName=${ss.fileName}&isPreview=${isPreview}&title=${ss.title}`, '_blank', 'noopener noreferrer')}><Icon variant='popout' /></div>
                                                                    :
                                                                    <div onClick={() => window.open(`/udocs/htmlviewer?manualCode=${manualCode}&fileName=${ss.fileName}&isPreview=${isPreview}&title=${ss.title}&manualName=${manualName}&revNumber=${revNumber}&revDate=${revDate}&fleet=${fleet}&isSsi=${ss.isSsi}`, '_blank', 'noopener noreferrer')}><Icon variant='popout' /></div>
                                                                    }
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    :
                                                    <ul key={ssi}>
                                                        <li className={styles['manual-sub-sec-title']} onClick={() => handleSubSectionClick(s.title, ss.title)}>
                                                            <div className={styles.title}>{ss.title}</div><div className={selectedSubSections.findIndex(sel => sel === s.title+ss.title) === -1 && currentSelectedSubSections.findIndex(sel=> sel===s.title+ss.title) === -1 ? styles['right-arrow'] : styles.arrow}></div>
                                                        </li>
                                                        {
                                                            ss.subTree ? ss.subTree.map((d, i) => {
                                                                return(
                                                                    d.fileName ? 
                                                                        <li key={i} className={cx(styles['doc-info'], {[styles['hide-section']]: selectedSubSections.findIndex(sel => sel === s.title+ss.title) === -1 && currentSelectedSubSections.findIndex(sel=> sel===s.title+ss.title) === -1})}>
                                                                            <div className={styles['doc-title']} onClick={() => {setViewerLoading(true); setSelectedDocument(d); handleSelectedTrail(s, ss, d); setPdfWorkerLoading(true); props.close();}}>
                                                                                <div>{d.title}</div>
                                                                                <div>{d.subTitle}</div>
                                                                            </div>
                                                                            <div className={styles['doc-actions']}>
                                                                                <div>
                                                                                    { !isPreview ? 
                                                                                        pinsinfo && pinsinfo.data && pinsinfo.data.pins.findIndex(p => p.fileName === d.fileName) !== -1 ? 
                                                                                        <Icon onClick={() => handlePinClick(false, d.fileName, d.title, d.subTitle)} className={styles['pinned-item']} variant='pinned' width='24px' height='23px' /> : 
                                                                                        <Icon onClick={() => handlePinClick(true, d.fileName, d.title, d.subTitle)} variant='unpinned' width='24px' height='23px' />
                                                                                        : null
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    !isHtmlManual ? 
                                                                                <div onClick={() => window.open(`/udocs/pdfviewer?manualCode=${manualCode}&fileName=${d.fileName}&isPreview=${isPreview}&title=${d.title}`, '_blank', 'noopener noreferrer')}><Icon variant='popout' /></div>
                                                                                :
                                                                                <div onClick={() => window.open(`/udocs/htmlviewer?manualCode=${manualCode}&fileName=${d.fileName}&isPreview=${isPreview}&title=${d.title}&manualName=${manualName}&revNumber=${revNumber}&revDate=${revDate}&fleet=${fleet}&isSsi=${d.isSsi}`, '_blank', 'noopener noreferrer')}><Icon variant='popout' /></div>
                                                                                }
                                                                            </div>
                                                                        </li>
                                                                    :
                                                                        null
                                                                )
                                                            }) :
                                                            null
                                                        }
                                                    </ul>
                                                )
                                            })
                                        }
                                        </li>
                                    </ul>
                                )
                            })
                        }
                        </li>
                    </ul>
                </div>
            : 
                <div className={`${styles['contents-spinner-container']} ${styles['spinner-section']}`}>
                    <div className={styles.spinner}></div>
                </div>
            }
        </>;

    return (tableContent?.manuals?.getManual?.isDocumentCollection)? <div className={"tablecontents"}> 
        {
           tableContent && tableContent.manuals ? 
                <div className={styles.tablecontents} style={{maxHeight: innerHeight}} ref={tableofcontent} onScroll={()=>{handleWindowScroll();}}>
                    <ul>
                        {/* <input id='manual-toggle' className={styles['manual-toggle']} type='checkbox' />  */}
                        <li className={styles['manual-title']}>{tableContent?.manuals?.getManual?.title}</li>
                        <li className={styles['manual-body']}> 
                        {
                            tableContent?.manuals?.getManual?.subTree?.length>0 && 
                            <div style={{ height: "700px"}}>
                            <Virtuoso
                            style={{ height: "700px"}} 
                            data={tableContent?.manuals?.getManual?.subTree}
                            className={"virtuoso-scroll-parent"}
                            itemContent = {(index, item)=>(
                                rowRenderer(index, item)
                            )
                            }
                            useWindowScroll={true}
                            />
                            </div>
                        }
                        </li>
                    </ul>
                </div>
            : 
                <div className={`${styles['contents-spinner-container']} ${styles['spinner-section']}`}>
                    <div className={styles.spinner}></div>
                </div>
            }

    </div> : defaultRenderer()

}

export default TableContents;