import React, { useState, useEffect, useRef, useContext } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import cx from 'classnames';

import styles from './search.module.scss';
import Icon from '../../components/Icon/Icon';
import PageTitle from '../../components/PageTitle/PageTitle';
import Select from '../../components/Controls/Select/Select';
import SearchFilters from '../../components/SearchFilters/SearchFilters';
import Alert from '../../components/Alert/Alert';
import { GET_EMP_MANUALS, GET_CURRENT_SEARCH, GET_ADVANCE_MANUAL_SEARCH } from '../../operations/queries/manuals';
import { mutations } from '../../operations/mutations';
import { GET_EMP_PROFILE } from '../../operations/queries/preferences';
import DocTable from '../../components/DocTable/DocTable';
import { ViewerContext } from '../Viewer/ViewerContext';
import { UserContext } from '../App/UserContext';

const Search = () => {
    const { userId } = useContext(UserContext);
    const profileinfo = useQuery(GET_EMP_PROFILE, {
		variables: {
		  userId: userId
		}
	  });
    const [getEmpManuals, { loading, data }] = useLazyQuery(GET_EMP_MANUALS);
    const [getAdvanceSearch, { loading: advanceSearching, data: advanceSearchResult }] = useLazyQuery(GET_ADVANCE_MANUAL_SEARCH);
    const { selectSearch } = mutations;
    const [params, setParams] = useState('');
    const [noResult, setNoResult] = useState(true);
    const { searchKeyword, setSearchKeyword,searchFound, setSearchFound, adSearchResult, setADSearchResult, fromViewer, setFromViewer, searchManuals, setSearchManuals, searchManualsDisplay, setSearchManualsDisplay } = useContext(ViewerContext);

    const [showSearch, setShowSearch] = useState(true);
    const [manualDisplay, setManualDisplay] = useState('');
    const [selectedManuals, setSelectedManuals] = useState([]);
    const [fleetDisplay, setFleetDisplay] = useState('');
    const [selectedFleets, setSelectedFleets] = useState([]);
    const [vendorDisplay, setVendorDisplay] = useState('');
    const [selectedVendors, setSelectedVendors] = useState([]);
    const [engineDisplay, setEngineDisplay] = useState('');
    const [selectedEngines, setSelectedEngines] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [expandAlert, setExpandAlert]=useState(true);
    const [tocHeight, setTOCHeight]=useState((window.innerHeight-260)+'px');
    const [newSearch, setNewSearch]=useState(true);
    const [resultOverLimit, setResultOverLimit]=useState(false);

    useEffect(() => {
        setFromViewer(false);
        if(!fromViewer)
        {
            setADSearchResult([]);            
        }
        else
        {
            setSelectedManuals([...searchManuals]);
            setManualDisplay(searchManualsDisplay);
            setParams(searchKeyword);
        }
    }, [])
    //setADSearchResult([...testdata]);
    useEffect(() => {
        if (profileinfo.data && profileinfo.data.profileQuery) {
            getEmpManuals({ variables: { employeeId: userId || 'V726082' } });
        }
    }, [profileinfo])
    
    useEffect(() => {
        /*const manualinfo = data && data.manuals && data.manuals.getManualsForEmployee ? 
                           data.manuals.getManualsForEmployee.find(m => m.manualName === selectedManuals) : 
                           null;
        setSelectedCategory(manualinfo); setFleetDisplay(''); setVendorDisplay(''); setEngineDisplay('');
        setSelectedFleets(''); setSelectedVendors(''); setSelectedEngines('');*/
        setSearchManuals([...selectedManuals]);
        setSearchManualsDisplay(manualDisplay);
        setNewSearch(true);
        console.log("multiselect", selectedManuals);
    }, [selectedManuals]);

    useEffect(() => {
        if(advanceSearchResult && advanceSearchResult.manuals && advanceSearchResult.manuals.advanceManualSearch) 
        {
            //console.log("adsearchResult", advanceSearchResult);
            setADSearchResult([...advanceSearchResult.manuals.advanceManualSearch]);
            if(advanceSearchResult.manuals.advanceManualSearch.length>0)
                setResultOverLimit(advanceSearchResult.manuals.advanceManualSearch[0].searchCount > 400);
        }
    }, [advanceSearchResult])

    const manualsRef = useRef(); const fleetsRef = useRef(); 
    const vendorsRef = useRef(); const enginesRef = useRef();

    const handleSearch = () => {
        const manualCodes = [];
        setNewSearch(false);
        setResultOverLimit(false);
        if(data && data.manuals && data.manuals.getManualsForEmployee)
        {
            selectedManuals.forEach(f => {
                
                const foundcategory = data.manuals.getManualsForEmployee.find(m => m.manualName === f);
                
                if(foundcategory && foundcategory.categoryName === 'Fleet')
                {
                    foundcategory.fleetManuals.forEach(fm=>{
                        manualCodes.push(fm.manualCode);
                    })
                }
                if(foundcategory && foundcategory.categoryName === 'Vendor')
                {
                    foundcategory.vendorManuals.forEach(vm=>{
                        manualCodes.push(vm.manualCode);
                    })
                }
                if(foundcategory && foundcategory.categoryName === 'Engine')
                {
                    foundcategory.engineManuals.forEach(em=>{
                        manualCodes.push(em.manualCode);
                    })
                }

    
                
                //different fleet type under same manual name with different manual code, Ad search needs to load for all fleet type or select from fleet level
                //if(foundmanual) manualCodes.push(foundmanual.manualCode);
            });

        }
        setSearchKeyword(params);
        getAdvanceSearch({ variables: { searchParams: params,  manualCode: manualCodes} });
        //console.log("adsearchResult", advanceSearchResult, params);
        /*if (selectedFleets.length === 0 && selectedVendors.length === 0 && selectedEngines.length === 0) selectSearch(selectedCategory);
        else {
            const manuals = selectedCategory.categoryName === 'Fleet' ? selectedCategory.fleetManuals.filter(m => selectedFleets.includes(m.fleetType)) :
                            selectedCategory.categoryName === 'Vendor' ? selectedCategory.vendorManuals.filter(m => selectedVendors.includes(m.vendorName)) :
                            selectedCategory.categoryName === 'Engine' ? selectedCategory.engineManuals.filter(m => selectedEngines.includes(m.engineType)) : [];
            
            const updatedCategory = {...selectedCategory};
            if (selectedCategory.categoryName === 'Fleet') updatedCategory.fleetManuals = [...manuals];
            if (selectedCategory.categoryName === 'Vendor') updatedCategory.vendorManuals = [...manuals];
            if (selectedCategory.categoryName === 'Engine') updatedCategory.engineManuals = [...manuals];
            
            selectSearch(updatedCategory);
        }*/
    }

/*    const handleKeypress=(params, manualCode, isPreview, e) => {
        console.log("key pressed", e.key);
        if (e.key === 'Enter') {
            getConfigDrawings({variables: { searchParams: params, manualCode: manualCode, isPreview : isPreview }});
            setNoResult(false);
          }
    }
*/
    // when window resize, set new alert box width for alert content box
    const handleWindowSizeChange = () => { 
        setTOCHeight((window.innerHeight-260)+'px');
    }
    // hood window resize event
    useEffect(()=> {       
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }    
    }, []);

    const saveSearchParamState = ( e) => {
        setParams(e.target.value);
        setNoResult(true);
        setNewSearch(true);
    }
    const currentsearch = useQuery(GET_CURRENT_SEARCH);
    const searchdata = currentsearch && currentsearch.data ? currentsearch.data : null;

    const manuals = data && data.manuals && data.manuals.getManualsForEmployee ? (data.manuals.getManualsForEmployee.filter(e=>e.isHtml && e.fleetManuals.length > 0 )).map(m => m.manualName) : [];
    //manuals.push("Select All");
    const employeeManuals = data && data.manuals && data.manuals.getManualsForEmployee ? data.manuals.getManualsForEmployee : null;
    return (
        <div className={styles.container}>
            <PageTitle title='Advanced Search' />
            <div className={styles['search-section']}>
                {/*<div className={styles.criteria}>
                    <div className={showSearch ? styles.arrow : styles['up-arrow']} onClick={() => setShowSearch(!showSearch)}></div>
                    <div>Search Criteria</div>
                    <hr />
    </div>*/}
                <div className={cx(styles['search-fields'], {[styles.hidden]: !showSearch})}>
                    <div className={styles.keyword}>
                        <div>Keyword</div>
                        <div className={styles.searchfield}>
                            <Icon variant='search' width='24px' clickable='false'/>
                            <input type='text' placeholder="search" defaultValue={fromViewer ? searchKeyword : ''} onChange={(e) => {saveSearchParamState(e)} } /*onKeyPress={(e) =>{handleKeypress(params, manualCode, isPreview, e)}}*//>
                        </div>
                        <div className={styles.line}></div>
                    </div>
                    <div className={styles.item}><Select selectref={manualsRef} title='Manuals' loading={loading} items={manuals} type='multi' selectedItems={selectedManuals} setSelectedItems={setSelectedManuals} selectedItem={manualDisplay} setSelectedItem={setManualDisplay} size='normal' ddHeight={tocHeight}/></div>
                    <div className={styles.item}><input type='button' value='Search' onClick={() => handleSearch()} disabled={params.length<=2 || selectedManuals.length<=0} /></div>
                </div>
            </div>
            <div>
            <Alert key={1} alertTitle={'Advanced search is only applicable for manuals listed in above drop down'} alertType={'I'} alertDescription={''} isManualAlert={false}/>
            {
                resultOverLimit ? 
                    <Alert key={1} alertTitle={'There are more than 400 results, please narrow down your search.'} alertType={'M'} alertDescription={''} isManualAlert={false}/>
                : null
            }
            </div>
            {
                resultOverLimit ? null
                :
                advanceSearching ?
                <div className={styles['spinner-section']}>
                <div className={styles.spinner}></div>
                </div>
                :
                adSearchResult.length>0 ?
                adSearchResult.map((a, index) => <DocTable empId={userId || 'V726082'} rowData={a} tableFrom={"Search"} employeeManual={employeeManuals}/>)
                : params.length>0 && selectedManuals.length>0 && !newSearch? 
                    <div className={styles.returndetail}>No search results found ...</div>
                    : null
             /*       <div className={styles.searchresult}>
                        <div className={styles.clapsetitle}>a.manualName
                            <div className={cx({[styles['up-arrow']]:expandAlert}, {[styles['down-arrow']]:!expandAlert}, styles['alertarrow'])} onClick={() => {setExpandAlert(!expandAlert);}}></div>
                        </div>                            
                    </div>
                    {
                        expandAlert ?
                        <div className={styles.returndetail}>
                        {advanceSearchResult.manuals.advanceManualSearch.advanceSearchDocuments ? advanceSearchResult.manuals.advanceManualSearch.advanceSearchDocuments.map((a, index) => <DocTable empId={userId || 'V726082'} rowData={a.advanceSearchDocuments} tableFrom={"Search"} employeeManual={employeeManuals}/>) 
                        : null
                        }
                        </div>    
                        : null
                    }
                    )
: null*/
            }
            {/* <SearchFilters /> 
            <div className={styles.tiles}>
                {   searchdata && searchdata.search ? 
                    searchdata.search.categoryName === 'Fleet' ?
                        searchdata.search.fleetManuals.map((f, index) => <DocTile key={index} title={searchdata.search.manualName} info={f} category={searchdata.search.categoryName} />) : 
                    searchdata.search.categoryName === 'Vendor' ?
                        searchdata.search.vendorManuals.map((v, index) => <DocTile key={index} title={searchdata.search.manualName} info={v} category={searchdata.search.categoryName} />) : 
                    searchdata.search.categoryName === 'Engine' ?
                        searchdata.search.engineManuals.map((e, index) => <DocTile key={index} title={searchdata.search.manualName} info={e} category={searchdata.search.categoryName} />) : 
                    null : null
                }
            </div>*/}
        </div>
    )
}

export default Search;