import React, { useState, useEffect, useContext } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation,useParams } from 'react-router-dom';
import ViewSDKClient from '../../pdfclient/ViewSDKClient';

import { ViewerContext } from './ViewerContext';

import styles from './viewer.module.scss';
import PageTitle from '../../components/PageTitle/PageTitle';
import { GET_DOCUMENT_V2 } from '../../operations/queries/documents';
import { GET_CURRENT_MANUAL, GET_FAVORITE_MANUALS, GET_MANUAL_PINS, GET_EMP_PINS,GET_EMP_MANUALS } from '../../operations/queries/manuals';
import { GET_ALERT } from '../../operations/queries/alert';
import Icons from '../../components/Icon/Icon';
import Slideout from '../../components/Slideout/Slideout';
import TableContents from '../../components/Slideout/TableContents/TableContents';
import TOCSearch from '../../components/Slideout/Search/Search';
import Pins from '../../components/Slideout/Pins/Pins';
import { GET_EMP_PROFILE } from '../../operations/queries/preferences';
import { mutations, UPDATE_EMP_FAV, UPDATE_EMP_MANUAL_PIN } from '../../operations/mutations';
import { UserContext } from '../App/UserContext.js';

//pdf plugin
import { Worker } from '@react-pdf-viewer/core';
import { Icon, MinimalButton, Position, Tooltip, Viewer, SpecialZoomLevel} from '@react-pdf-viewer/core';
import { defaultLayoutPlugin, ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { bookmarkPlugin } from '@react-pdf-viewer/bookmark';
import '@react-pdf-viewer/bookmark/lib/styles/index.css';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import { NextIcon, PreviousIcon, RenderSearchProps, searchPlugin } from '@react-pdf-viewer/search';
import '@react-pdf-viewer/search/lib/styles/index.css';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import ShadowDOM from "../../components/ShadowDOM";
import config from '../../config/development.js';
import DsViewer from '../../components/PdfProViewer/DsViewer.js';
//end pdf plugin


const PdfViewer = (props) => {
    const { userId } = useContext(UserContext);
    const history = useHistory();
    const location = useLocation();
    const {manualcode}=useParams()
    const manualResult = useQuery(GET_CURRENT_MANUAL);
    const favmanuals = useQuery(GET_FAVORITE_MANUALS);
    const profileinfo = useQuery(GET_EMP_PROFILE, {
		variables: {
		  userId: userId
		}
	  });
    const pinsinfo = useQuery(GET_MANUAL_PINS);

    //pdf plugin
    const [urlPath, setUrlPath] =useState("");
    const bookmarkPluginInstance = bookmarkPlugin();
    const { Bookmarks } = bookmarkPluginInstance;
    const defaultLayoutPluginInstance = defaultLayoutPlugin();


    const thumbnailPluginInstance = thumbnailPlugin();
    const { Thumbnails } = thumbnailPluginInstance;
    const searchPluginInstance = searchPlugin();
    const { Search } = searchPluginInstance;
    const fullScreenPluginInstance = fullScreenPlugin();
    const { EnterFullScreenButton } = fullScreenPluginInstance;
    //end pdf plugin

    //pdf plugin
    const [readyToSearch, setReadyToSearch] = useState(false);
    //end pdf plugin

    const [getDocument, { loading, data }] = useLazyQuery(GET_DOCUMENT_V2);
    const [getEmpManuals, {  data: manualData }] = useLazyQuery(GET_EMP_MANUALS);
    //const [getCurrentManuals, {data: manualResult}] =  useLazyQuery(GET_CURRENT_MANUAL);

      useEffect(() => {
            if (profileinfo.data && profileinfo.data.profileQuery) {
                getEmpManuals({ variables: { employeeId:userId || 'V726082'  } });
            }
        }, [profileinfo])

    const [updateFavorite] = useMutation(UPDATE_EMP_FAV);
    const { setFavorites,selectManual } = mutations;
    const { setPins } = mutations;
    const [updateManualPin] = useMutation(UPDATE_EMP_MANUAL_PIN);
    const [udocsUrl, setUdocsUrl]=useState('http://localhost:3000/udocs')

    const { selectedDocument, setSelectedDocument, isViewerLoading, setViewerLoading, isHtml, setScrollPosition, previousManualCode, setPreviousManualCode, setCurrentSelectedSections, setCurrentSelectedSubSections, isSearchEnabled, selectedTrail, setSelectedTrail, searchKeyword, setSearchKeyword,searchFound, setSearchFound, adSearchResult, setFromViewer, tableContent, pdfWorkerLoading, setPdfWorkerLoading,setHtml,setSearchEnabled } = useContext(ViewerContext);

    const [isContentSelected, setContentSelected] = useState(true);
    const [isSearchSelected, setSearchSelected] = useState(false);
    const [isBookmarkSelected, setBookmarkSelected] = useState(false);
    const [htmlBase64, setHtmlBase64]=useState();
    const [revDetails, setRevDetails] = useState();
    const [expandAlert, setExpandAlert]=useState(false);
    const [alertBoxWidth, setAlertBoxWidth]=useState();
    const [complianceTag, setComplianceTag]=useState(false);
    const [tocHeight, setTOCHeight]=useState((window.innerHeight-260)+'px');
    const [titleTrail, setTitleTrail]=useState();
    const [bannerDetail, setBannerDetail]=useState('');
    const [bannerSubTitle, setBannerSubTitle]=useState('');
    const [searchLegend, setSearchLegend]=useState(false);
    const [preExist, setPreExist]=useState(false);
    const [nextExist, setNextExist]=useState(false);
    const [currentFileFromSearch, setCurrentFileFromSearch]=useState('');

    const [getAlerts, { loading: alertLoading, data: alertData }] = useLazyQuery(GET_ALERT);
    const alertBoxRef = React.useRef(null);
    //configureing udocs URLS
    useEffect(()=>{
        if(config.ENV==="development")
        { setUdocsUrl( `https://udocs.dev.efo.aws.ual.com/udocs`)}
        else if (config.ENV ==="qa")
        {setUdocsUrl(`https://udocs.qa.efo.aws.ual.com/udocs`)}
        else if (config.ENV==='stage')
        {setUdocsUrl(`https://udocs.stg.efo.aws.ual.com/udocs`)}
        else if(config.ENV==='production')
        {setUdocsUrl(`https://udocs.prd.efo.aws.ual.com/udocs`)}
        else
        {setUdocsUrl(`http://localhost:3000/udocs`)}
    },[])
    //Loading Manual Data in viewer page
       useEffect(()=>{
        const employeeManuals =  manualData &&  manualData.manuals &&  manualData.manuals.getManualsForEmployee ?  manualData.manuals.getManualsForEmployee : [];
       
        if(employeeManuals){
           const foundcategory = employeeManuals.find(m => m.fleetManuals.findIndex(fm => fm.manualCode === manualcode) !== -1 || 
           m.vendorManuals.findIndex(vm => vm.manualCode === manualcode) !== -1 || 
           m.engineManuals.findIndex(em => em.manualCode === manualcode) !== -1);
const foundmanual = foundcategory && foundcategory.categoryName === 'Fleet' ? foundcategory.fleetManuals.find(fm => fm.manualCode === manualcode) : 
           foundcategory && foundcategory.categoryName === 'Vendor' ? foundcategory.vendorManuals.find(vm => vm.manualCode === manualcode) : 
           foundcategory && foundcategory.categoryName === 'Engine' ? foundcategory.engineManuals.find(em => em.manualCode === manualcode) : null;
              
        
        const newrecent = {
           categoryName:(foundcategory?foundcategory.categoryName:null),
           manualName:(foundcategory?foundcategory.manualName:null)  ,
           manualCode:manualcode,
           hasAlerts:(foundmanual?foundmanual.hasAlerts:null),
           revDate:(foundmanual?foundmanual.revDate:null) , 
           revNumber:(foundmanual?foundmanual.revision:null),
           uRL: null,
           typeInfo:(foundmanual?foundmanual.fleetType || foundmanual.vendorName || foundmanual.engineType || null:null),
           fleetType:(foundmanual?foundmanual.fleetType || null:null),
           vendorName:null, 
           engineType:  null
           };
        
           selectManual(newrecent) 
            setSelectedDocument(null)
          {foundcategory?setHtml(foundcategory.isHtml) :setHtml(false)}
          {foundcategory?setSearchEnabled(foundcategory.isSearchEnabled):setSearchEnabled(true)} 
     
        }
   },[manualData])
    //reset table of content scroll position by different manual
    if((manualResult && manualResult.data && manualResult.data.manual))
    {
        if((manualResult.data.manual.manualCode+"pb")!==previousManualCode)
        {
            setScrollPosition(0);
            setCurrentSelectedSections([]);
            setCurrentSelectedSubSections([]);
        }

        setPreviousManualCode(manualResult.data.manual.manualCode+'pb');
    }

    //preset data while navigate from Search page
    useEffect(() => {
        if(location.state && location.state==='search')
        {
            setSearchLegend(true);
            setCurrentFileFromSearch(selectedDocument.fileName);
            //setContentSelected(false);
        }
    }, [location.state])

    //looking for Metadata path once a manual is clicked
    useEffect(() => {
        const otherPath=([]);
        let foundManual=false;
        if(tableContent && selectedDocument)
        {
            tableContent.manuals.getManual.subTree.forEach(t => {
                //   debugger
                if(!foundManual)
                {
                    otherPath.push(t.title);
                    if(t.fileName)
                    {
                        if(t.fileName===selectedDocument.fileName)
                        {
                            setSelectedTrail([...otherPath]);
                            foundManual=true;
                            //otherPath.clear();
                        }
                    }
                    else
                    {
                        if(t.subTree)
                        {
                            t.subTree.forEach(s=>{
                                if(!foundManual)
                                {
                                    otherPath.push(s.title);
                                    if(s.fileName)
                                    {
                                        if(s.fileName===selectedDocument.fileName)
                                        {
                                            setSelectedTrail([...otherPath]);
                                            foundManual=true;
                                            //otherPath.clear();
                                        }
                                    }
                                    else
                                    {
                                        if(s.subTree)
                                        {
                                            s.subTree.forEach(ss=>{

                                                if(ss.fileName===selectedDocument.fileName)
                                                {
                                                    otherPath.push(ss.title);
                                                    setSelectedTrail([...otherPath]);
                                                    foundManual=true;
                                                    //otherPath.clear();
                                                }
                                            })
                                        }
                                    }
                                    if(!foundManual) otherPath.pop();
                                }
                            });
                        }
                    }
                    if(!foundManual) otherPath.pop();
                }
            });
            if(!tableContent?.manuals?.getManual?.isDocumentCollection) {
                setContentSelected(false);
            }
        }
    }, [tableContent,selectedDocument])

    // if ((!manualResult || !manualResult.data || !manualResult.data.manual)) history.push('/');

    useEffect(() => {
        if((manualResult && manualResult.data && manualResult.data.manual))
        {
            getAlerts({variables: { manualCode: manualResult.data.manual.manualCode }});
        }
    }, [manualResult])

    useEffect(() => {
        if (selectedDocument && manualResult && manualResult.data && manualResult.data.manual) {
            if(!isHtml)
            {
                setViewerLoading(true);
                setUrlPath(null);
                //pdf plugin
                fetch(config.REACT_APP_DOC_PRESIGNEDURL + manualResult.data.manual.manualCode+"/"+selectedDocument.fileName)
                .then(res => { console.log("--response--", res); return res.json()})
                        .then((res) => {
                            console.log("=====", res);
                            fetch(res.s3PreSignedUrl)
                .then(res => res.blob())
                .then(blob => {
                  var newBlob = new Blob([blob], {type: "application/pdf"})
                   console.log(newBlob);
                  setUrlPath(URL.createObjectURL(newBlob));
              });
              setViewerLoading(false);
            })
              //end pdf plugin
            }else{
                if(profileinfo.data )
                {
                    getDocument({variables: { manualCode: manualResult.data.manual.manualCode, fileName: selectedDocument.fileName , isPreview: false, isHtml: isHtml, includeComplianceInformation: complianceTag, employeeId: userId }});
                }
            }
           } else{
                setViewerLoading(false)
            }

    }, [selectedDocument,profileinfo,complianceTag])

    //set display of Metadata Path
    useEffect(() => {
        if (selectedDocument && manualResult && manualResult.data && manualResult.data.manual) {
            if(selectedTrail)
            {
                let tbannerDetail='';
                let tbannerSubTitle='';

                if(selectedTrail.length>1)
                {
                    for(var i=0; i<selectedTrail.length-1; i++)
                    {
                        tbannerSubTitle=tbannerSubTitle + selectedTrail[i] + '/ ';
                        console.log("banner", bannerSubTitle, selectedTrail);
                    }
                    tbannerDetail=selectedTrail[selectedTrail.length-1];
                }
                else
                    tbannerDetail=selectedTrail[0];

                setBannerDetail(tbannerDetail);
                setBannerSubTitle(tbannerSubTitle);
            }
        }
    }, [selectedDocument,selectedTrail])

    useEffect(() => {
         if(isHtml)
         {
            setHtmlBase64("");
            if (data && data.contentStore && data.contentStore.viewFileV2)
            {
                setRevDetails(data.contentStore.viewFileV2.revDetails)
                fetch(config.REACT_APP_DOC_PRESIGNEDURL + manualResult.data.manual.manualCode+"/"+selectedDocument.fileName+"/"+!data.contentStore.viewFileV2.isPreview)
                .then(res => { return res.json()})
                        .then((res) => {
                            fetch(res.s3PreSignedUrl)
                            .then(response => response.text())
                            .then(htmlContent => {
                              let processedHtml = htmlContent
                                .replace(/\b/g, '')    // Remove "\b"
                                .replace(/\n/g, '')    // Remove newlines
                                .replace(/\r/g, '')    // Remove carriage returns
                                .replace('<!DOCTYPE html  SYSTEM "about:legacy-compat">', '')
                                .replace('data:image/jpg; base64, ', 'data:image/jpeg;charset=utf-8;base64,')
                                .replace(/<br>/g, '<br/>');
                          
                              if (!complianceTag) {
                                const parser = new DOMParser();
                                const doc = parser.parseFromString(processedHtml, 'text/html');
                                const complianceNodes = doc.querySelectorAll('span.compliance');
                                complianceNodes.forEach(node => node.remove());
                                processedHtml = doc.documentElement.innerHTML;
                              }
                              processedHtml = processedHtml.replace("<html>", "<html>" + revDetails);
                              setHtmlBase64(processedHtml);
                              //when user view manual from Advance Search result, replace returned HTML with keyword highlighted 
                              if(searchLegend && searchKeyword && searchFound)
                                {
                                    //const re = new RegExp(`${searchKeyword}`, 'gi');
                                    const re = new RegExp(`((?<!<[^>]*)${searchKeyword})`, 'gi');
                                    setHtmlBase64(processedHtml.replace(re, '<mark style="background-color:#69B3E7">$&</mark>'));
                                }  
                            })
                            .catch(error => console.error('Error fetching or processing HTML content:', error));
                })
            }
            else { setViewerLoading(false) }
        }
    }, [data])
    const handleFavoriteClick = (isFavorite) => {
        const currentfavs = [...favmanuals.data.favorites];
        const userId1 = userId || 'V726082';
        if (userId1) {
            if (isFavorite) currentfavs.unshift({ manualCode: manualResult.data.manual.manualCode });
            else {
                const index = currentfavs.findIndex(f => f.manualCode === manualResult.data.manual.manualCode);
                if (index !== -1) currentfavs.splice(index, 1);
            }
            updateFavorite({ variables: {
                    "employeeId": userId,
                    "manualCode": manualResult.data.manual.manualCode,
                    "isActive": isFavorite
                }
            });

            setFavorites(currentfavs);
        }
    }

    // when window resize, set new alert box width for alert content box
    const handleWindowSizeChange = () => {
        //alertBoxRef.current.width='200px';
        if (alertBoxRef && alertBoxRef.current)
        {
            setAlertBoxWidth((alertBoxRef.current.clientWidth-38)+'px');
            //console.log("alertbox",alertBoxRef.current.clientWidth);
        }
        setTOCHeight((window.innerHeight-260)+'px');
    }
    // hood window resize event
    useEffect(()=> {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(()=> {
        if(adSearchResult && selectedDocument)
        {
            const adManual = adSearchResult.find(m => m.manualCode === manualResult.data.manual.manualCode);
            if(adManual)
            {
                if(adManual.searchCount>1)
                {
                    const index = adManual.advanceSearchDocuments.findIndex(p => p.fileName === selectedDocument.fileName );
                    console.log("searchbox", adSearchResult, adManual, selectedDocument.fileName, index)
                    if(index!==-1)
                    {
                        if(index>0) setPreExist(true);
                        if(index<adManual.searchCount-1) setNextExist(true);
                    }
                }

            }
        }
    }, [adSearchResult, selectedDocument ]);

    const handlePrintClick=(ishtml,ssi)=>{
        if(ishtml)
        {
        var pwa = window.open('htmlBase64', '', '');
        var start=htmlBase64.indexOf('<title>');
        var end= htmlBase64.indexOf('</title>');
        if(ssi===true)
            pwa.document.write(

              '<table>' +
              `<thead style="height: 120px;" ><tr style=""><td><div style='display:flex'><p style="font-size:10px ">${moment().format(
                "MM/DD/YYYY hh:mm A")}
                </p> <p style="font-size:10px;margin-left:250px;position: relative;">  ${manualResult.data.manual.manualName}  </p>  </div>
                 </td>
                 </tr>
                 <tr style="padding:"0px"><td><p style="font-size:10px;margin-left:345px;position: fixed;top: 40px">Uncontrolled, One Time Use Only | rev#: ${manualResult.data.manual.revNumber} | rev date:${moment(manualResult.data.manual.revDate).format('MM/DD/YYYY')}</p></td></tr>
                 <tr><td><div style="font-size:16px;text-align:center; color:red!important; font-weight:bold;print-color-adjust: exact !important;">SENSITIVE SECURITY INFORMATION</div></td></tr>
                 </thead>` +
              "<td>" +
              htmlBase64.substring(0, start) +
              htmlBase64.substring(end) +
              "</td> " +
              `<tfoot>
              <tr>
              <td style="color:red">SSI WARNING: THIS RECORD CONTAINS SENSITIVE SECURITY INFORMATION THAT IS CONTROLLED UNDER 49 C.F.R. PARTS 15 AND 1520. NO PART OF THIS RECORD MAY BE DISCLOSED TO PERSONS WITHOUT A "NEED TO KNOW", AS DEFINED IN 49 C.F.R. PARTS 15 AND 1520, EXCEPT WITH THE WRITTEN PERMISSION OF THE ADMINISTRATOR OF THE TRANSPORTATION SECURITY ADMINISTRATION OR THE SECRETARY OF HOMELAND SECURITY. UNAUTHORIZED RELEASE MAY RESULT IN CIVIL PENALTY OR OTHER ACTION. FOR U.S. GOVERNMENT AGENCIES, PUBLIC DISCLOSURE IS GOVERNED BY 5 U.S.C. 552 AND 49 C.F.R. PARTS 15 AND 1520.1.</td>
              </tr>
            </tfoot>`+
              "</table>"
          )
          else{
            pwa.document.write(
              '<table>' +
                      `<thead style="height: 80px;" ><tr style=""><td><div style='display:flex'><p style="font-size:10px ">${moment().format(
                        "MM/DD/YYYY hh:mm A")}
                        </p> <p style="font-size:10px;margin-left:250px;position: relative;">  ${manualResult.data.manual.manualName}  </p>  </div>
                         </td>
                         </tr>
                         <tr style="padding:"0px""><td><p style="font-size:10px;margin-left:345px;position: fixed;top: 40px">Uncontrolled, One Time Use Only | rev#: ${manualResult.data.manual.revNumber} | rev date: ${moment(manualResult.data.manual.revDate).format('MM/DD/YYYY')}</p></td></tr>
                         </thead>` +
                      "<td>" +
                      htmlBase64.substring(0, start) +
                      htmlBase64.substring(end) +
                      "</td> " +
                      "</table>"
            );
          }
        pwa.print();
        pwa.close();
        }
        else
        {
            var pwa = window.open(urlPath, "pdfwindowprint");
            console.log(pwa);
            setTimeout(() => {
                pwa.print();
                }, 50);
            // pwa.close();
        }
        //window.open('_blank', 'noopener noreferrer')
    }

    const handlePinClick = (isPinning, fileName, title, subTitle) => {
        //debugger
        const currentpins = [...pinsinfo.data.pins];
        if (isPinning) currentpins.unshift({ manualCode: manualResult.data.manual.manualCode, fileName: fileName, title: title, subTitle: subTitle});
        else {
            const index = currentpins.findIndex(p => p.fileName === fileName);
            if (index !== -1) currentpins.splice(index, 1);
        }
        setPins(currentpins);
        updateManualPin({ variables: {
                employeeId: userId || 'V726082',
                manualCode: manualResult.data.manual.manualCode,
                fileName: fileName,
                isActive: isPinning
            }
        });
    }

    const handleDocumentNavClick=(direction) =>
    {
        const adManual = adSearchResult.find(m => m.manualCode === manualResult.data.manual.manualCode);
        if(adManual)
        {
                const index = adManual.advanceSearchDocuments.findIndex(p => p.fileName === currentFileFromSearch );

                if(index!==-1)
                {
                    if(direction==="prev")
                    {
                        if(index-1>0) setPreExist(true);
                        else setPreExist(false);
                    }
                    if(direction==="next")
                    {
                        if(index+1<adManual.searchCount-1) setNextExist(true);
                        else setNextExist(false);
                    }
                        const navFileName = direction==="prev" ? adManual.advanceSearchDocuments[index-1].fileName : adManual.advanceSearchDocuments[index+1].fileName
                        const navCount = direction==="prev" ? adManual.advanceSearchDocuments[index-1].count : adManual.advanceSearchDocuments[index+1].count
                        const newfile = {
                            fileName: navFileName,
                            hasChildren: false,
                            hasDocument: true,
                            isHtml: null,
                            subTitle: null,
                            title: null
                        }
                        setSelectedDocument(newfile);
                        setSearchFound(navCount);
                        setCurrentFileFromSearch(navFileName);
                }
        }
    }
    const handleDocumentLoad = (e) => {
        const { activateTab } = defaultLayoutPluginInstance;
        setPdfWorkerLoading(false);
        // Activate the bookmark tab whose index is `1` only for MEL manual
        //if(manualResult.data.manual.manualName==='MEL')
            activateTab(1);
    };

    const isFavorite = manualResult && manualResult.data && favmanuals.data && favmanuals.data.favorites.findIndex(f => f.manualCode === manualResult.data.manual.manualCode) !== -1 ? true : false;
    return (
        <>
        <div className={styles.rectangle}></div>
        {
            manualResult && manualResult.data && manualResult.data.manual ?
                <div className={cx(styles.container, {[styles.containerHTML]: !isHtml}) }>
                    <div className={styles.history}></div>
                    <div className={styles.actions}>
                        <div className={styles.textual}>
                            <PageTitle title={manualResult.data.manual.manualName} />
                            <div className={styles.info}>
                                <div className={styles['rev-section']}>
                                    <div>
                                        <div>{manualResult.data.manual.typeInfo}</div>
                                        <div>{`Rev # ${manualResult.data.manual.revNumber ? manualResult.data.manual.revNumber : '--'} - ${moment(manualResult.data.manual.revDate).format('MM/DD/YYYY')}`}</div>
                                    </div>
                                    <div><Icons variant={isFavorite ? 'favorite' : 'unfavorite'} width="26px" height="25px" onClick={() => handleFavoriteClick(!isFavorite)} clickable='true'/></div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.buttons}>
                            <div className={cx(styles.button, {[styles.active]: isContentSelected || isSearchSelected})} onClick={() => {setContentSelected(!isContentSelected); setSearchSelected(false); setBookmarkSelected(false);}}>
                                <div className={`${styles.icon} ${styles.contents}`}><Icons variant='document' width='30px' height='40px' clickable='false' /></div>
                                <div className={styles.text}>Table of contents</div>
                            </div>
                            {
                            //isSearchEnabled ?
                            //<div className={cx(styles.button/*, {[styles.active]: isSearchSelected}*/)} onClick={() => {setSearchSelected(!isSearchSelected); setContentSelected(false); setBookmarkSelected(false);}}>
                            //    <div className={styles.icon}><Icons variant='search' width='30px' height='40px' clickable='false' /></div>
                            //    <div className={styles.text}>TOC Search</div>
                            //</div>
                            //: null
                            }
                            <div className={cx(styles.button, {[styles.active]: isBookmarkSelected})} onClick={() => {setBookmarkSelected(!isBookmarkSelected); setContentSelected(false); setSearchSelected(false);}}>
                                <div className={`${styles.icon} ${styles.pins}`}><Icons variant='unpinned' width='30px' height='40px' clickable='false' /></div>
                                <div className={styles.text}>Pins</div>
                            </div>

                            <div className={cx(styles['slideout'], {[styles['slideout-view']]: isContentSelected})}>
                                {   isContentSelected ?
                                    <Slideout title='Table of Contents' close={() => setContentSelected(false)}  switchtocsearch={()=>setSearchSelected(true)}  copyhandler={() => {navigator.clipboard.writeText(`${udocsUrl}/viewer/${manualResult.data.manual.manualCode}`)}} manualCode={manualcode}>
                                        <TableContents manualCode={manualResult.data.manual.manualCode} isPreview={false} setSelectedDocument={setSelectedDocument} setViewerLoading={setViewerLoading} isHtmlManual={isHtml} innerHeight={tocHeight} close={() => setContentSelected(false)}  manualName={manualResult.data.manual.manualName}revNumber={manualResult.data.manual.revNumber} revDate={moment(manualResult.data.manual.revDate).format('MM/DD/YYYY')} fleet={manualResult.data.manual.typeInfo} />
                                    </Slideout>
                                    : null
                                }
                            </div>
                            <div className={cx(styles['slideout'], {[styles['slideout-view']]: isSearchSelected})}>
                                {   isSearchSelected ?
                                    <Slideout title='TOC Search' close={() => setSearchSelected(false)}>
                                        <TOCSearch manualCode={manualResult.data.manual.manualCode} isPreview={false} setSelectedDocument={setSelectedDocument} setViewerLoading={setViewerLoading} isHtmlManual={isHtml}  innerHeight={(window.innerHeight-300)+'px'} close={() => setSearchSelected(false)} switchtocontent={()=>setContentSelected(true)}/>
                                    </Slideout>
                                    : null
                                }
                            </div>
                            <div className={cx(styles['slideout'], {[styles['slideout-view']]: isBookmarkSelected})}>
                                {   isBookmarkSelected ?
                                    <Slideout title='Pinned Documents' close={() => setBookmarkSelected(false)}>
                                        <Pins manualCode={manualResult.data.manual.manualCode} isPreview={false} setSelectedDocument={setSelectedDocument} setViewerLoading={setViewerLoading} isHtmlManual={isHtml}  innerHeight={tocHeight} close={() => setBookmarkSelected(false)} />
                                    </Slideout>
                                    : null
                                }
                            </div>
                        </div>
                        {
                            alertData && alertData.alerts && alertData.alerts.getAlerts.activeAlerts.length>0 ?
                            <div className={styles.alertcontainer}>
                                <div className={styles.alertbox} ref={alertBoxRef}>
                                    <div className={styles.alert}><div className={styles.itemEX}>!</div></div>
                                    <div>{alertData && alertData.alerts ? alertData.alerts.getAlerts.activeAlerts[0].alertTitle : ''}</div>
                                    <div className={cx({[styles['up-arrow']]:expandAlert}, {[styles['down-arrow']]:!expandAlert}, styles['alertarrow'])} onClick={() => {setExpandAlert(!expandAlert);setAlertBoxWidth((alertBoxRef.current.clientWidth-38)+'px');}}></div>
                                </div>
                                {
                                    alertData && alertData.alerts  && alertData.alerts.getAlerts.activeAlerts.length>0 && expandAlert ?
                                    <div className={styles.alertcontentbox} style={{width: alertBoxWidth}}>
                                        {alertData && alertData.alerts ? alertData.alerts.getAlerts.activeAlerts[0].alertDescription : ''}
                                    </div>
                                    : null
                                }
                            </div>
                        : null
                        }
                        {
                           /* alertData && alertData.alerts  && alertData.alerts.getAlerts.activeAlerts.length>0 && expandAlert ?
                            <div className={styles.alertcontentbox} style={{width: alertBoxWidth}}>
                                {alertData && alertData.alerts ? alertData.alerts.getAlerts.activeAlerts[0].alertDescription : ''}
                            </div>
                            : null*/
                        }
                        <div className={styles.rightbox}>
                        {
                            isHtml ?
                                <div className={styles.comptag}>View Compliance Tag
                                <Icons variant={complianceTag ? 'toggle-on' : 'toggle-off'} width='36px' height='20px' clickable='true' onClick={() => {setComplianceTag(!complianceTag);}}/>
                                </div>
                            : null
                        }
                        {
                            selectedDocument ?
                            pinsinfo && pinsinfo.data && pinsinfo.data.pins.findIndex(p => p.fileName === selectedDocument.fileName) !== -1 ?
                            <div className={styles.pingicon}><Icons onClick={() => handlePinClick(false, selectedDocument.fileName, selectedDocument.title, selectedDocument.subTitle)} className={styles['pinned-item']} variant='pinned' width='37.5px' height='37.17px' clickable='true'/></div> :
                            <div className={styles.pingicon}><Icons  onClick={() => handlePinClick(true, selectedDocument.fileName, selectedDocument.title, selectedDocument.subTitle)} variant='unpinned' width='37.5px' height='37.17px' clickable='true' />
                            <div>Pin This Document</div>
                            </div>
                            : null
                        }
                        </div>
                        {
                            searchLegend && searchKeyword && searchFound ?
                        <div className={styles.bottombox}>
                            <div className={styles.searchlegend}>
                                <div className={styles.navigation}>
                                    <div className={cx(styles['left-arrow'], {[styles['arrow-disable']] : !preExist})} onClick={()=>handleDocumentNavClick('prev')} ></div>
                                    <div className={cx({[styles['arrow-disable']] : !preExist})}>Prev document</div>
                                </div>
                                <div className={styles.underline} onClick={()=>{setFromViewer(true); history.push('/Search')}}>Edit Search</div>
                                <div className={cx(styles.innerbox, styles["innerbox-wide"])}><div className={styles.innertitle}>Keyword </div>"{searchKeyword}"</div>
                                <div className={cx(styles.innerbox, styles["innerbox-center"])}><div className={styles.innertitle}>Results in this document </div>{searchFound}</div>
                                <div className={styles.navigation}>
                                    <div className={cx({[styles['arrow-disable']] : !nextExist})}>Next document</div>
                                    <div className={cx(styles['right-arrow'],styles['right-arrow-nomargin'], {[styles['arrow-disable']] : !nextExist})} onClick={()=>handleDocumentNavClick('next')}></div>
                                </div>
                                <div className={styles['modal-close']} onClick={()=>setSearchLegend(false)}></div>
                            </div>
                        </div>
                        : null
                        }
                        {
                               selectedDocument ?
                               <div className={styles.infobar}>
                                    <div className={styles.titlebanner}>
                                        <div className={styles.title}>
                                            {bannerSubTitle}
                                            <div className={styles.subjecttext}>{bannerDetail}</div>
                                        </div>
                                        
                                        <div className={styles.titlebannericon}>
                                            {/* {isHtml ?  */}
                                            {manualResult.data.manual.manualCode==123025 ? "" :
                                            <div className={styles.bannericon} onClick={() => handlePrintClick(isHtml,selectedDocument.isSsi)}><Icons variant='print' width='30px' height='34px' clickable={true}/></div>}
                                            {/* :null} */}
                                            {
                                                !isHtml ?
                                                <div className={styles.icon} onClick={() => window.open(`/udocs/pdfviewer?manualCode=${manualResult.data.manual.manualCode}&fileName=${selectedDocument.fileName}&isPreview=${false}&title=${bannerDetail}`, '_blank', 'noopener noreferrer')}><Icons variant='popout-gray' width='40px' height='40px' clickable={true}/></div>
                                                :
                                                <div className={styles.icon} onClick={() => window.open(`/udocs/htmlviewer?manualCode=${manualResult.data.manual.manualCode}&fileName=${selectedDocument.fileName}&isPreview=${false}&title=${bannerDetail}&manualName=${manualResult.data.manual.manualName}&revNumber=${manualResult.data.manual.revNumber}&revDate=${moment(manualResult.data.manual.revDate).format('MM/DD/YYYY')}&fleet=${manualResult.data.manual.typeInfo}&isSsi=${selectedDocument.isSsi}`, '_blank', 'noopener noreferrer')}><Icons variant='popout-gray' width='40px' height='40px' clickable={true}/></div>
                                            }
                                        </div>

                                    </div>
                                    {isHtml ?
                                    <div className={styles.helpbar}>
                                        <div className={styles.helpbox}>
                                            <div className={styles.itemI}>i</div>
                                            <div>Use "CTRL+F" to search for text within the current page</div>
                                        </div>
                                    </div>
                                    : null}
                                </div>

                                : null
                    }
                        </div>

                        {console.log('ishtml', isHtml)/* <div dangerouslySetInnerHTML={{ __html: `<iframe src='${config.REACT_APP_ASSET_BASE}/tech-routes.html' width='100%' height='800px' frameBorder='0' />` }}></div> */}
                        {!isHtml && (window.navigator.userAgent.indexOf('Firefox') > -1) ? <div className={styles['firefox-warning']}><sup>*</sup>Printing from the PDF Viewer is not fully supported in Firefox.</div> : null}

                        {

                            isHtml ?
                                loading ?
                                    <div className={styles['spinner-section']}>
                                        <div className={styles.spinner}></div>
                                    </div>
                                    : (
                                        <ShadowDOM>
                                            <div dangerouslySetInnerHTML={{ __html: htmlBase64 }}></div>
                                        </ShadowDOM>
                                    )
                                :

                                <div id='pdf-viewer'>
                                    {
                                        isViewerLoading ? <div className={styles['pdf-loading']}><div>Viewer loading please wait...</div></div> :
                                        <div className={styles["pdf-viewer"]}>
                                            <div   style={{
                                                //border: '1px solid rgba(0, 0, 0, 0.3)',
                                                height: '735px',
                                                width: '100%',
                                            }} >
                                            {
                                                pdfWorkerLoading?
                                                <div className={styles['spinner-section']}>
                                                    <div className={styles.spinner}></div>
                                                </div>
                                                :null
                                            }
                                                 {manualResult.data.manual.manualCode==123025 ? urlPath && <DsViewer fileUrl={urlPath} viewerLoaded={handleDocumentLoad}/> :
                                                urlPath && <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js">
                                                    {/* <div
                                                        style={{
                                                            alignItems: 'center',
                                                            backgroundColor: '#eeeeee',
                                                            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                                            display: 'flex',
                                                            padding: '4px',
                                                        }}
                                                    >
                                                        <Search>
                                                            {(renderSearchProps) => {
                                                                return (
                                                                    <>
                                                                        <div
                                                                            style={{
                                                                                border: '1px solid rgba(0, 0, 0, 0.3)',
                                                                                display: 'flex',
                                                                                padding: '0 2px',
                                                                            }}
                                                                        >
                                                                            <input
                                                                                style={{
                                                                                    border: 'none',
                                                                                    padding: '8px',
                                                                                    width: '200px',
                                                                                }}
                                                                                placeholder="Enter to search"
                                                                                type="text"
                                                                                value={renderSearchProps.keyword}
                                                                                onChange={(e) => {
                                                                                    setReadyToSearch(false);
                                                                                    renderSearchProps.setKeyword(e.target.value);
                                                                                }}
                                                                                onKeyDown={(e) => {
                                                                                    if (e.keyCode === 13 && renderSearchProps.keyword) {
                                                                                        setReadyToSearch(true);
                                                                                        renderSearchProps.search();
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <Tooltip
                                                                                position={Position.BottomCenter}
                                                                                target={
                                                                                    <button
                                                                                        style={{
                                                                                            background: '#fff',
                                                                                            border: 'none',
                                                                                            borderBottom: `2px solid ${renderSearchProps.matchCase ? 'blue' : 'transparent'
                                                                                                }`,
                                                                                            height: '100%',
                                                                                            padding: '0 2px',
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            renderSearchProps.changeMatchCase(!renderSearchProps.matchCase)
                                                                                        }
                                                                                    >
                                                                                        <Icon>
                                                                                            <path d="M15.979,21.725,9.453,2.612a.5.5,0,0,0-.946,0L2,21.725" />
                                                                                            <path d="M4.383 14.725L13.59 14.725" />
                                                                                            <path d="M0.5 21.725L3.52 21.725" />
                                                                                            <path d="M14.479 21.725L17.5 21.725" />
                                                                                            <path d="M22.5,21.725,18.377,9.647a.5.5,0,0,0-.946,0l-1.888,5.543" />
                                                                                            <path d="M16.92 16.725L20.794 16.725" />
                                                                                            <path d="M21.516 21.725L23.5 21.725" />
                                                                                        </Icon>
                                                                                    </button>
                                                                                }
                                                                                content={() => 'Match case'}
                                                                                offset={{ left: 0, top: 8 }}
                                                                            />
                                                                            <Tooltip
                                                                                position={Position.BottomCenter}
                                                                                target={
                                                                                    <button
                                                                                        style={{
                                                                                            background: '#fff',
                                                                                            border: 'none',
                                                                                            borderBottom: `2px solid ${renderSearchProps.wholeWords ? 'blue' : 'transparent'
                                                                                                }`,
                                                                                            height: '100%',
                                                                                            padding: '0 2px',
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            renderSearchProps.changeWholeWords(!renderSearchProps.wholeWords)
                                                                                        }
                                                                                    >
                                                                                        <Icon>
                                                                                            <path d="M0.500 7.498 L23.500 7.498 L23.500 16.498 L0.500 16.498 Z" />
                                                                                            <path d="M3.5 9.498L3.5 14.498" />
                                                                                        </Icon>
                                                                                    </button>
                                                                                }
                                                                                content={() => 'Match whole word'}
                                                                                offset={{ left: 0, top: 8 }}
                                                                            />
                                                                        </div>
                                                                        {readyToSearch &&
                                                                            renderSearchProps.keyword &&
                                                                            renderSearchProps.numberOfMatches === 0 && (
                                                                                <div style={{ padding: '0 8px' }}>Not found</div>
                                                                            )}
                                                                        {readyToSearch && renderSearchProps.keyword && renderSearchProps.numberOfMatches > 0 && (
                                                                            <div style={{ padding: '0 8px' }}>
                                                                                {renderSearchProps.currentMatch} of {renderSearchProps.numberOfMatches}
                                                                            </div>
                                                                        )}
                                                                        <div style={{ padding: '0 2px' }}>
                                                                            <Tooltip
                                                                                position={Position.BottomCenter}
                                                                                target={
                                                                                    <MinimalButton onClick={renderSearchProps.jumpToPreviousMatch}>
                                                                                        <PreviousIcon />
                                                                                    </MinimalButton>
                                                                                }
                                                                                content={() => 'Previous match'}
                                                                                offset={{ left: 0, top: 8 }}
                                                                            />
                                                                        </div>
                                                                        <div style={{ padding: '0 2px' }}>
                                                                            <Tooltip
                                                                                position={Position.BottomCenter}
                                                                                target={
                                                                                    <MinimalButton onClick={renderSearchProps.jumpToNextMatch}>
                                                                                        <NextIcon />
                                                                                    </MinimalButton>
                                                                                }
                                                                                content={() => 'Next match'}
                                                                                offset={{ left: 0, top: 8 }}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                );
                                                            }}
                                                        </Search>
                                                    </div> */}

                                                    <div
                                                        style={{
                                                            border: '1px solid rgba(0, 0, 0, 0.3)',
                                                            display: 'flex',
                                                            height: '100%',
                                                        }}
                                                    >
                                                        {/* <div
                                                            style={{
                                                                borderRight: '1px solid rgba(0, 0, 0, 0.3)',
                                                                overflow: 'auto',
                                                                width: '30%',
                                                            }}
                                                        >
                                                            <Thumbnails />
                                                        </div> */}
                                                        {/* <div
                                                            style={{
                                                                borderRight: '1px solid rgba(0, 0, 0, 0.3)',
                                                                overflow: 'auto',
                                                                width: '30%',
                                                            }}
                                                        >
                                                            <Bookmarks />
                                                        </div> */}
                                                        <div  style={{ flex: 1 }}>
                                                            <Viewer fileUrl={urlPath} plugins={[defaultLayoutPluginInstance,
                                                                 bookmarkPluginInstance,
                                                                  thumbnailPluginInstance,
                                                                   searchPluginInstance]} onDocumentLoad={handleDocumentLoad} defaultScale={SpecialZoomLevel.PageFit}/>
                                                        </div>
                                                    </div>


                                                </Worker>}

                                            </div>
                                        </div>
                                    }
                                </div>

                       }
                    </div>
                    : null
            }
        </>
    )
}

export default PdfViewer;
